<template>
    <div>
        <b-container fluid class="index-header" v-if="homePage">
            <b-row>
                <b-col cols="2" offset-xl="1">
                   <router-link to="/">
                        <img class="img-fluid" src="../assets/logo.svg">
                   </router-link>
                </b-col>
                <b-col cols="8" xl="7" class="text-right navigation">
                    <router-link class="nav-link" to="/" data-title="Autopflege"><span>Autopflege</span></router-link>
                    <router-link class="nav-link" to="coupons" data-title="Gutscheine"><span>Gutscheine</span></router-link>
                    <router-link class="nav-link" to="about" data-title="Über uns"><span>Über uns</span></router-link>
                    <router-link class="nav-link" to="offer" data-title="Stellenangebote"><span>Stellenangebote</span></router-link>
                </b-col>
                <b-col cols="2" xl="1">
                    <img class="img-fluid claim" src="../assets/claim.svg">
                </b-col>
                <b-col cols="12" class="text-center">
                    <h1>Glänzende Zukunft<br> für Ihr Auto!</h1>
                    <p>Erstklassige Aufbereitung und Reinigung<br> aller Fahrzeugtypen</p>

                   <!-- <form v-on:submit.prevent="searchCity">
                       <div class="input-group justify-content-center">
                           <input v-model="zipcode" type="text" class="start" placeholder="PLZ eingeben"/>
                           <div class="input-group-append">
                               <button type="submit" class="input-group-text" id="basic-addon2">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                   </svg>
                               </button>
                           </div>
                       </div>
                   </form> -->

                   <a @click="scrollToStart" class="button">Autopflege buchen</a>
                   <router-link to="coupons" class="button">Gutschein buchen</router-link>
                </b-col>
            </b-row>
        </b-container>
        <b-container fluid class="page-header " v-if="!homePage">
            <b-row>
                <b-col cols="2" offset-xl="1">
                    <a href="/"><img class="img-fluid" src="../assets/logo.svg"></a>
                </b-col>
                <b-col cols="8" xl="7" class="text-right navigation">
                    <router-link class="nav-link" to="/" data-title="Autopflege"><span>Autopflege</span></router-link>
                    <router-link class="nav-link" to="coupons" data-title="Gutscheine"><span>Gutscheine</span></router-link>
                    <router-link class="nav-link" to="about" data-title="Über uns"><span>Über uns</span></router-link>
                    <router-link class="nav-link" to="offer" data-title="Stellenangebote"><span>Stellenangebote</span></router-link>
                </b-col>
                <b-col cols="2" xl="1">
                    <img class="img-fluid claim" src="../assets/claim.svg">
                </b-col>
                <b-col cols="12" class="text-center">
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import VueScrollTo from 'vue-scrollto';

    export default {
        name: 'Header',
        data() {
            return {
                zipcode: ''
            }
        },
        methods: {
            scrollToStart(){
                VueScrollTo.scrollTo('#start', 700, {offset: -85})
            },
            searchCity(){
                console.log(this.zipcode)
            }
        },
        computed: {
            homePage() {
                if(this.$route.path == "/" || this.$route.path == "/home" ) {
                    return true
                } else {
                    return false
                }
            }
        },
    }
</script>
<style lang="scss" scoped>

    .navigation{
        a{
            text-align:center;
            display:inline-block;
            color:#FFF;
            font-size:18px;
            letter-spacing: 0.9px;
            font-weight:400;
            margin-top:15px;

            @media(max-width: 1024px) {
                padding: 0.5rem;
                margin-top: -15px;
            }

            @media(max-width: 1280px) {
                margin-top: 0;
            }

            &.router-link-exact-active,
            &:hover{
                font-family: 'futura-pt-bold';
                color: #FFF;
                text-decoration: none;
            }

            &::before {
                display: block;
                content: attr(data-title);
                font-family: 'futura-pt-bold';
                height: 0;
                overflow: hidden;
                visibility: hidden;

                @media(max-width: 768px) {
                    display: none
                }
            }
        }
    }
    .button{
        margin:0 30px;
    }

</styles>