<template>
    <div>
        <img class="img-fluid" src="../assets/smart-header.jpg" />
        <div class="container-fluid smart-container">
            <div class="row">
                <h3 class="col-md-12 text-center">Wir können noch mehr<br> mit Smart Repair.</h3>
                <div class="col-md-8 offset-md-2 col-xl-6 offset-xl-3">
                    <p>Unser Leistungspaket umfasst weitere Angebote, für die eine individuelle Begutachtung nötig ist. Vereinbaren Sie unverbindlich einen Termin bei uns vor Ort und lassen Sie sich beraten.</p>
                </div>
                <div class="col-md-5 offset-md-2 col-xl-2 offset-xl-4">
                    <ul class="list-unstyled">
                        <li>+ Entfernung von Steinschlägen</li>
                        <li>+ Entfernung von Schrammen</li>
                        <li>+ Entfernung von Dellen</li>
                        <li>+ Beilackieren</li>
                    </ul>
                </div>
                <div class="col-md-5 col-xl-2 offset-xl-1">
                    <ul class="list-unstyled">
                        <li>+ Stoffreparatur</li>
                        <li>+ Lederreparatur</li>
                        <li>+ Smart Repair</li>
                    </ul>
                </div>
                <div class="col-12 text-center" v-if="!bookingVisible">
                    <a @click.prevent="openBooking" class="button">Termin vereinbahren</a>
                </div>
                <div class="col-xl-5 offset-xl-4 booking-field" v-if="bookingVisible">
                    <p>Hinterlassen Sie Ihre Telefonnummer und wir melden uns in spätestens 24 Stunden.</p>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Telefonnummer" aria-label="Telefonnummer">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary button" type="button" id="send-phone">Absenden</button>
                        </div>
                    </div>
                    <p>Oder rufen Sie uns an, unter 0351 285 79 285</p>
                </div>

                <div class="col-12 grey-bg google-row">
                    <h4 class="col-md-12 text-center">Bewertungen auf Google</h4>
                    <GoogleReviews slidesPerView="3"></GoogleReviews>
                </div>
            </div>
        </div>
        <footer>

            <div class="container-fluid red-bg">
                <div class="row">

                    <div class="col-md-8 col-lg-7 offset-lg-1 col-xl-4 offset-xl-2">
                        <h4>Geschenkidee gesucht?</h4>
                    </div>

                    <div class="col-md-4 col-xl-3">
                        <router-link to="coupons"><a class="button white">Gutschein buchen</a></router-link>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4 col-xl-2 offset-xl-2">
                        <h5>Kontakt</h5>
                        <ul class="list-unstyled">
                            <li>Dohnaer Straße 310</li>
                            <li>01257 Dresden</li>
                            <li>Telefon 0351 285 792 85</li>
                            <li><a class="mail" href="mailto:info@waschprofis.de">info@waschprofis.de</a></li>
                            <li>&nbsp;</li>
                            <li><a target="_blank" href="https://www.google.de/maps/dir//Dohnaer+Str.+310,+01257+Dresden/@50.987405,13.8093994,16.25z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4709c707dcabf051:0x18d4ff98cc55a5da!2m2!1d13.8122636!2d50.9885398!3e0">
                                <svg  viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="geo alt fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-geo-alt-fill b-icon bi" style="font-size: 200%;"><g ><path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path></g></svg><span class="direction">Anfahrt</span></a></li>
                        </ul>
                    </div>
                    <div class="col-md-4 col-xl-2 offset-xl-1">
                        <h5>Öffnungszeiten</h5>
                        <ul class="list-unstyled">
                            <li>Mo–Fr 07:00–19:00 Uhr</li>
                        </ul>
                    </div>
                    <div class="col-md-4 col-xl-2 offset-xl-1">
                        <h5>Unternehmen</h5>
                        <ul class="list-unstyled">
                            <li><router-link to="about" data-title="Über uns"><span>Über uns</span></router-link></li>
                            <li><router-link to="offer" data-title="Stellenangebote"><span>Stellenangebote</span></router-link></li>
                            <li><router-link to="coupons" data-title="Gutscheine"><span>Gutscheine</span></router-link></li>
                            <li><router-link to="/" data-title="Buchung"><span>Buchung</span></router-link></li>
                        </ul>
                    </div>
                    <div class="col-md-4 offset-md-4 text-md-center offset-xl-0 col-xl-2">
                        <img class="seal" src="../assets/seal.svg" />
                    </div>
                </div>
                <div class="row sub-footer">
                    <div class="col-md-5 offset-xl-1">
                        © 2021 Waschprofis
                    </div>
                    <div class="col-md-6 col-xl-5 text-right">
                        <ul class="list-unstyled list-inline">
                            <li class="list-inline-item"><router-link to="terms"><span>AGB</span></router-link></li>
                            <li class="list-inline-item"><router-link to="privacy"><span>Datenschutz</span></router-link></li>
                            <li class="list-inline-item"><router-link to="imprint"><span>Impressum</span></router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
    import GoogleReviews from "@/components/GoogleReviews.vue";

    export default {
        name: 'Footer',
        components:{
            GoogleReviews,
        },
        data() {
            return {
                bookingVisible: false,
            }
        },
        methods: {

            openBooking() {
                this.bookingVisible = true;
            }

        }
    }
</script>
<style lang="scss" scoped>

    .smart-container{
        margin-top:140px;
        padding-bottom:120px;

        h3{
            font-family: 'futura-pt-bold';
            font-size: 60px;
            letter-spacing: 1.5px;
            margin-bottom:65px;
        }

        p{
            font-weight: 500;
            font-size: 22px;
            letter-spacing: 0.55px;
            margin-bottom:75px;
        }

        ul{
            font-size: 21px;
            margin-bottom:75px;
        }
    }

    .booking-field{
        margin-top: 60px;

        p{
            margin-bottom:15px;
        }


        .form-control,
        .form-check{
            height: 65px;

            &.is-invalid{
                border: thin solid #dc3545;
            }
        }
    }

    .google-row{
        margin-top: 120px;
        padding: 100px 115px;

        h4{
            font-size: 40px;
            letter-spacing: 1px;
            line-height: 1.625em;
            margin-bottom: 1.625em;
        }
    }


    footer{
        h5{
            margin-bottom: 2em;
        }

        .seal{
            width:70%;
            transform: rotate(15deg);
            margin-top: 30px;
            margin-left: -75%;
        }

        .sub-footer{
            margin-top: 60px;
        }

        ul{

            li{
                margin-bottom:15px;

                @media(min-width: 1025px) {
                    margin-bottom:30px;
                }
            }
        }

        .direction{
            display: inline-block;
            margin-left:1rem;
        }
    }

    @media(min-width: 1200px) {
        .offset-xl-1,
        .offset-xl-2 {

            margin-left: 0;
        }
        .offset-lg-1.offset-xl-2 {
            margin-left: 8.333333%;
        }

        .col-xl-2 {
            flex: 0 0 25%;
            max-width: 25%;
        }
        .col-xl-3 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }

        .col-xl-4 {
            flex: 0 0 58.333333%;
            max-width: 58.333333%;
        }
    }

</styles>